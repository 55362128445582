import styled from '@emotion/styled'
import { Accordions } from 'app/components/Accordions'
import { AdvancedIntro } from 'app/components/AdvancedIntro'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { ModalBox } from 'app/components/ModalBox'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { VisualBanner } from 'app/components/VisualBanner'
import { DaySpaQuotationForm } from 'app/containers/DaySpaQuotationForm'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import {
  DaySpaQuotationFormSenderBackend,
  DaySpaQuotationFormSenderBackendConfiguration,
} from 'app/utils/DaySpaQuotationFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  daySpaQuotationFormSenderBackendConfiguration: DaySpaQuotationFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function DaySpaPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      DaySpaQuotationFormSenderBackend(
        pageContext.daySpaQuotationFormSenderBackendConfiguration,
      ),
    [pageContext.daySpaQuotationFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.advancedIntroProps ? (
        <AdvancedIntro variant="large" {...context.advancedIntroProps} />
      ) : null}
      {context.galleryImagesProps ? (
        <GalleryImages {...context.galleryImagesProps} />
      ) : null}
      {context.accordionsProps ? (
        <Accordions {...context.accordionsProps} />
      ) : null}
      {context.modalBoxProps ? <ModalBox {...context.modalBoxProps} /> : null}
      {context.visualBannerProps ? (
        <VisualBanner {...context.visualBannerProps} />
      ) : null}
      <DaySpaQuotationForm
        {...(context.daySpaQuotationFormProps
          ? context.daySpaQuotationFormProps
          : null)}
        /* treatment={state.test} */
        onDaySpaQuotationFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
      />
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          variant="compact"
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``

import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { Minus, Plus } from 'react-feather'

export interface Props {
  description?: string
  isFirst?: boolean
  isExpanded?: boolean
  title?: string
}

export const Item = memo(function Item({ description, isFirst, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container uuid={isFirst ? 'first' : undefined}>
      <Head>
        <Title>
          <Button>
            <State>{({ expanded }) => (expanded ? <Minus /> : <Plus />)}</State>
          </Button>
          {title}
        </Title>
      </Head>
      {description ? (
        <Panel dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  margin-top: 1.875rem;
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  white-space: nowrap;
  &:after {
    content: '';
    flex: 1 1 0%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    height: 0.125rem;
    opacity: 0.2;
    margin-left: 3rem;
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  background: ${rgba(theme.colors.variants.neutralLight4, 0.1)};
  border-radius: 50%;
  margin-right: 1.25rem;
  svg {
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  opacity: 0.5;
  margin-top: 1.5rem;
`

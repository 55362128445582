import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname: string
  email: string
  phone?: string
  arrival_date: string
  time: string
  people: number
  message?: string
  treatment?: string
  privacy_policy: boolean
  newsletter_subscription?: boolean
}

export interface DaySpaQuotationFormSenderBackendConfiguration {
  formURL: string
  suiteMailURL: string
  languageCode: string
}

export type DaySpaQuotationFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const DaySpaQuotationFormSenderBackend =
  ({
    formURL,
    suiteMailURL,
    languageCode,
  }: DaySpaQuotationFormSenderBackendConfiguration): DaySpaQuotationFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })

      const formData = new FormData()
      formData.append('list', '1')
      formData.append('group', '216')
      formData.append('confirm', 'off')
      formData.append('campo1', data.name)
      formData.append('campo2', data.lastname)
      formData.append('email', data.email)
      formData.append('campo31', languageCode)

      await axios({
        method: 'POST',
        url: suiteMailURL,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        data: formData,
      })

      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }

export interface DaySpaQuotationFormSenderSlackConfiguration {
  webhookURL: string
}

export type DaySpaQuotationFormSenderSlack = (input: {
  data: FormData
  backendError?: AxiosError
}) => Promise<Either<AxiosError, void>>
